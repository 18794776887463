// -----------------------------------------------------------------------------
// Définition des polices utilisées
// -----------------------------------------------------------------------------

/// Montserrat
/// OFL - Julieta Ulanovsky

$montserrat-font-path: '#{$font-path}/montserrat';

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $weight-normal;
  src: url('#{$montserrat-font-path}/montserrat-regular.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-regular.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $weight-normal;
  src: url('#{$montserrat-font-path}/montserrat-italic.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-italic.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $weight-medium;
  src: url('#{$montserrat-font-path}/montserrat-medium.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-medium.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $weight-bold;
  src: url('#{$montserrat-font-path}/montserrat-bold.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-bold.woff') format('woff');
}

/// SF Pixelate
/// Freeware - Derek Vogelpohl

$sf-pixelate-font-path: '#{$font-path}/sf-pixelate';

@font-face {
  font-family: 'SF-Pixelate';
  font-style: normal;
  font-weight: $weight-normal;
  src: url('#{$sf-pixelate-font-path}/sf-pixelate.woff') format('woff');
}

@font-face {
  font-family: 'SF-Pixelate';
  font-style: normal;
  font-weight: $weight-bold;
  src: url('#{$sf-pixelate-font-path}/sf-pixelate-bold.woff') format('woff');
}
