// -----------------------------------------------------------------------------
// Variables de l'application et des librairies
// -----------------------------------------------------------------------------

$font-path: '../fonts';

/// Bulma
//- utilities/initial-variables

// Colors

$black: #333330;
$white-ter: #f3f5f2;

$purple: #992bf0;
$pink: #fe08ff;

// Typography

$family-sans-serif: Montserrat, sans-serif;

$size-1: 4rem;
$size-2: 3rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;

$weight-normal: 400;
$weight-medium: 500;
$weight-bold: 700;

// Responsiveness

$fullhd-enabled: false;

//- utilities/derived-variables

$primary: $purple;

$text: $black;

$link: $primary;
$link-visited: $pink;

$family-secondary: 'SF-Pixelate';

//- base/generic

$strong-color: $pink;

//- elements/content

$content-heading-weight: $weight-bold;

//- elements/title

$subtitle-negative-margin: -1rem;

//- components/navbar

$navbar-background-color: transparent;
$navbar-box-shadow-size: 0 0 4px 0;

$navbar-breakpoint: 0;
