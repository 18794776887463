@charset 'utf-8';

// Variables et utilitaires
@import 'abstracts/variables';

// Librairies
@import 'vendor/bulma';
@import 'vendor/fork-awesome';

// Styles de base
@import 'base/fonts';

// -----------------------------------------------------------------------------

html {
  scroll-behavior: smooth;
}

// ajoute une transition
.navbar-item {
  transition: all 100ms ease-in-out;

  // masque le nom du site sur l'accueil
  .navbar-brand & {
    opacity: 1;

    &.is-active {
      opacity: 0;
      background: none;
    }
  }
}

// ajoute de l'espace pour afficher la barre
.hero.is-fullheight {
  padding-top: $navbar-height / 2;
}

// ajoute de l'espace en haut et en bas
.box {
  &.is-spaced {
    --boxSpacing: 1rem;
    margin-top: var(--boxSpacing);

    &:not(:last-child) {
      margin-bottom: var(--boxSpacing);
    }

    @include desktop {
      --boxSpacing: 2rem;
    }
  }
}
